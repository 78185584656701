.userInfoContent {
  margin-top: 4rem;
  padding: 1rem;
}

.userInfoItem {
  padding-bottom: 2rem;
  font-size: 1.25rem;
}

.userInfoName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.userInfoDescriptionContent {
  white-space: pre-wrap;
  /* padding-bottom: 1rem; */
  line-height: 1.25;
}

.userInfoNameUpdate {
  padding: 1rem 1rem 1rem 1rem;
  border: 0.5px solid gray;
  border-radius: 4px;
}

.userInfoLanguage {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.userInfoNameUpdateButton {
  padding: 2rem 0;
  display: flex;
  justify-content: flex-end;
}

.userInfoLanguageClose {
  text-align: right;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.userInfoLanguageCloseButton {
  cursor: pointer;
}

.userInfoUserImage {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.userInfoUserNoImage {
  font-size: 40px;
}