.nftOffer {
  padding: 2rem 0
}

.nftOfferListContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nftOfferList {
  /* width: 50%; */
  width: 100%;
}

@media (min-width: 480px) {
  .nftOfferListContainer {
    flex-direction: row;
    gap: 0;
  }

  .nftOfferList {
    width: 100%
  }
}