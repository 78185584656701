.issuerFooter {
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 5rem;
  font-size: 0.8rem;
}

.issuerFooterRow {
  padding-bottom: 1rem;
}

.issuerFooterPageTitle {
  font-size: 0.75rem;
}