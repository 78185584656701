.navigation-item {
  padding: 0 1.5rem;
}

.navigation-item a {
  text-decoration: none;
  color: white;
}

.navigation-item.mobile {
  font-size: 1.75rem;
  margin: 0.5rem 0;
}

.navigation-item.mobile a {
  color: #3b0062;
  color: var(--color);
}

.navigation-item a:hover,
.navigation-item a:active,
.navigation-item a.active {
  color: #fab83f;
}

.navigation-item:last-of-type {
  padding-right: 0;
}

.navigation-item button {
  font: inherit;
  background: transparent;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0;
}

.navigation-item button:hover,
.navigation-item button:active {
  color: #fab83f;
}

.navigation-item-mobile-titleContainer {
  display: flex;
  align-items: center;
}

.navigation-item-mobile-titleIcon {
  height: 1.5rem;
  margin-right: 1rem;
}

.toggleNavItem {
  padding: 0 0 1rem 0;
  font-size: 1.5rem;
  
}

.toggleNavItemIcon {
  padding-right: 1rem;
  cursor: pointer;
}

.toggleNavItemText {
  color: var(--color-gray-700);
  cursor: pointer;
}

.toggleNavItemEmail {
  padding: 1rem;
}



.toggleNavItemSpacer {
  background: gray;
  height: 0.1rem;
  width: 100%; 
  margin-bottom: 1.5rem;
}


.toggleNavItemLinks {
  font-weight: normal;
}

.toggleNavItemLinksLink {
  font-size: 1rem;
}

.toggleNavItemLinkDesc {
  font-size: 1rem;
  font-weight: normal;
  padding-left: 1rem;
}