.createSellOfferInput {
  padding-bottom: 2rem;
  max-width: 90%;
}

.createSellOfferBuyAssetInfo {
  padding-top: 0.5rem;
  word-break: break-all;
}

.createSellOfferAvailableXlm {
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
}