.userImage {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.userImageImage {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 4px;
}