.NftListList {
  display: block;
  /* flex-wrap: wrap; */
}

.NftListListContent {
  /* white-space: nowrap;
  flex-basis: 45%;
  flex-grow: 1; */
  display: flex;
  justify-content: center;
}

@media (min-width: 480px) {
  .NftListList {
    display: flex;
    flex-wrap: wrap;
  }
  
  .NftListListContent {
    white-space: nowrap;
    flex-basis: 48%;
    flex-grow: 1;
  }
}