.userNftItem {
  margin-bottom: 1rem;
  padding: 1rem;
  /* border: 0.25px solid gray; */
  border-radius: 4px;
}

.userNftMediaContainer {
  text-align: center;
}

.userNftItemUpdate {
  padding: 1rem 0;
}

.userNftItemUpdateClose {
  text-align: right;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.userNftItemUpdateCloseButton {
  cursor: pointer;
}

.userNftItemAuthButtons {
  padding-top: 1rem;
  padding-bottom: 1rem;
}