.userImageClose {
  text-align: right;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.userImageCloseButton {
  cursor: pointer;
}

.userImageUploadContent {
  padding-bottom: 2rem;
}

.userImageImageEditorLink {
  padding: 1rem;
  font-size: 0.9rem;
}

.userImagePreview {
  height: 100px;
  width: 100px;
  object-fit: cover;
}