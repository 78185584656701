.backdrop {
  position: fixed;
  /* z-index: 1; */
  z-index:50;
  background-color: rgba(0, 0, 0, 0.30);
  /* background-color: rgba(0, 0, 0, 0.15); */
  width: 100%;
  height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
}