.nftListItem {
  padding: 0.5rem 0.5rem 4rem 0.5rem;
  /* padding-bottom: 4rem; */
}

.nftListItemMediaContainer {
  /* min-height: 100px; */
  text-align: center;
}

.nftListItemCreator {
  display: flex;
  /* justify-content: center; */
  gap: 0.5rem;
  align-items: center;
}

.nftListItemCreatorImage {
  height: 1.25rem;
  width: 1.25rem;
  object-fit: cover;
  border-radius: 2px;
}