.nftCommentItem {
  padding: 0 1rem 2.5rem 1rem;
  /* padding-bottom: 1.5rem; */
}

.nftCommentAuthor {
  display: flex;
  /* justify-content: center; */
  gap: 0.25rem;
  align-items: center;
  font-size: 0.9rem;
  padding-left: 1rem;
}

.nftCommentAuthorImage {
  height: 1rem;
  width: 1rem;
  object-fit: cover;
  border-radius: 2px;
}

.nftCommentContent {
  white-space: pre-wrap;
  padding-bottom: 1rem;
  line-height: 1.25;
}

.nftCommentDeleteButton {
  display: flex;
  justify-content: flex-end;
}

.nftCommentClose {
  text-align: right;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.nftCommentCloseButton {
  cursor: pointer;
}