.fileUploadInput {
  padding: 0 0 2rem 0;
  max-width: 90%;
}

.fileUploadStartButton {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  padding: 2rem;
  /* padding-right: 2rem; */
  /* cursor: pointer; */
}

.fileUploadAvailableXlm {
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
  /* font-weight: bolder; */
}

.fileUploadAuth {
  padding: 2rem;
  font-weight: bold;
}


.fileUploadAuthButtons {
  display: flex;
  /* gap: 1rem; */
  align-items: center;
  padding-top: 1rem;
}

.fileUploadWalletLink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
}

.fileUploadImageEditorLink {
  padding: 1rem;
  font-size: 0.9rem;
}