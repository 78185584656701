.nftSellOfferItem {
  border: 0.1px solid gray;
  padding: 0.5rem 1rem;
}

.nftSellOfferItemPrice {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
}

.nftSellOfferItemLogo {
  width: 0.9rem;
  height: 0.9rem;
}