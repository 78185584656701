.nftBuyOfferItem {
  border: 0.1px solid gray;
  padding: 0.5rem 1rem;
}

.nftBuyOfferItemPrice {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
}

.nftBuyOfferItemLogo {
  width: 0.9rem;
  height: 0.9rem;
}