.userNftListCreator {
  font-size: 1.25rem;
  padding: 0 1.5rem 1rem 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
}

.userNftListCreatorImage {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 2px;
}

.userNftListCreatorDescription {
  width: 90%;
  max-width: 40rem;
  line-height: 1.25;
  margin: 0 auto;
  padding: 0 1rem 2rem 1rem;
}

.userNftListSelectButtons {
  display: flex;
  padding-bottom: 0.5rem;
}

/* .userNftListList {
  display: flex;
  flex-wrap: wrap;
}

.userNftListListContent {
  white-space: nowrap;
  flex-basis: 49%;
  flex-grow: 1;
} */
.userNftListListContent {
  display: flex;
  justify-content: center;
}

.userNftListTopAd {
  padding-top: 2rem;
}

@media (min-width: 768px) {
  .userNftListList {
    display: flex;
    flex-wrap: wrap;
  }
  
  .userNftListListContent {
    white-space: nowrap;
    flex-basis: 49%;
    flex-grow: 1;
  }
}