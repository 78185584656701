.singleNftMediaContainer {
  display: flex;
  flex-direction: column;
}

.singleNftMedia {
  width: 100%;
  min-height: 250px;
  padding: 0.5rem;
  text-align: center;
  /* cursor: pointer; */
}

.singleNftDesc {
  width: 100%;
  padding: 0.5rem;
  word-break: break-all;
  max-height: 100vh;
  overflow-y: auto;
  font-size: 1.1rem;
}

.singleNftDescTitle {
  padding-bottom: 0.5rem;
}

.singleNftDescContent {
  padding-left: 0.5rem;
  padding-bottom: 1rem;
  white-space: pre-wrap;
  line-height: normal;
}

.singleNftDetailButoon {
  padding: 1rem 0;
  cursor: pointer;
}

.singleNftTabs {
  /* padding: 1rem 0; */
  margin: 0 0.5rem;
  padding-top: 3rem;
  display: flex;
  gap: 0.5rem;
}

.singleNftTab {
  padding: 0.75rem 1rem;
  border-radius: 4px;
  /* border: 1px solid wheat; */
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  color: var(--pal-text-primary);
  background: var(--pal-background-secondary);
}

.singleNftTab:hover {
  background: var(--pal-background-tertiary);
}

.singleNftWalletLogin {
  display: flex;
  padding-top: 1rem;
  padding-left: 0.5rem;
}

.singleNftButtonLink {
  color: var(--pal-background-secondary);
}

@media (min-width: 480px) {
  .singleNftMediaContainer {
    flex-direction: row;
  };

  .singleNftMedia {
    width: 50%;
  }
  
  .singleNftDesc {
    width: 50%;
  }
}