.followingUsersTitle {
  cursor: pointer;
}

.followingUserName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.followingUserImage {
  height: 1.5rem;
  width: 1.5rem;
  object-fit: cover;
  border-radius: 2px;
}