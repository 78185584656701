.notification {
  width: 100%;
  /* height: 3rem; */
  /* background-color: #1a8ed1; */
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 5%;
  align-items: center;
  /* color: white; */

  max-width: 70%;
  /* width: 40%; */
  position: fixed;
  bottom: 5%;
  left: 5%;
  right: 5%;
  z-index: 500;

  /* background-color:gray; */
  background-color: var(--pal-background-tertiary);
  /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25); */
  box-shadow: var(--shadow-medium);
  border: 0.25px solid gray;
  /* border: 0.25px solid var(--pal-background-tertiary); */
  border-radius: 2px;
  opacity: 0.9;
  font-weight: bolder;
}

.notification h2,
.notification p {
  font-size: 1rem;
  margin: 0;
}

.error {
  /* background-color: #690000; */
  /* opacity: 0.9; */
  color: darkred;
}

.success {
  background-color: #1ad1b9;

  background-color: gray;
  color: white;
  opacity: 0.9;
}

.notificationClear {
  cursor: pointer;
}