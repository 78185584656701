.userOffers {
  font-size: 1rem;
}

.userOffersTitle {
  font-size: 1.5rem;
  text-align: center;
}

.userOffersTableContainer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.userOffers table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 95%;
}

.userOffers td {
  padding-top: 1rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.userOffers th {
  padding-left: 0 0.2rem;
}

.userOffers tr {
  word-break: break-all;
}

.userOffersId {
  font-size: 0.8rem;
}

.userOffersAsset {
  /* font-size: 0.8rem; */
}

.userOffersPrice {
  font-size: 0.8rem;
}

.userOffersAmount {
  font-size: 0.8rem;
}

.userOffersTimeHeader {
  display: none;
}

.userOffersTime {
  display: none;
  font-size: 0.8rem;
}

.userOffersDetilLink {
  padding-top: 1rem;
  text-align: center;
}

.userOffersAds {
  padding-top: 5rem;
}

.userOffersConnectWallet {
  padding-left: 1rem;
  padding-top: 1rem;
}

.userOffersShowMore {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.userOfferCancelConetent {
  padding-bottom: 1rem;
}

.userOfferCancelAvailableXlm {
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 480px) {
  .userOffersId {
    font-size: 1rem;
    min-width: 6rem;
    text-align: center;
  }
  
  .userOffersAsset {
    font-size: 1rem;
    min-width: 6rem;
    text-align: center;
  }

  .userOffersPrice {
    /* font-size: 1rem; */
    min-width: 6rem;
    text-align: center;
  }
  
  .userOffersAmount {
    font-size: 0.8rem;
    min-width: 6rem;
    text-align: center;
  }

  /* .userOffersTimeHeader {
    display: block;
  }

  .userOffersTime {
    display: block;
    font-size: 1rem;
    text-align: center;
  } */
}

@media (min-width: 768px) {

  .userOffersTimeHeader {
    display: block;
  }

  .userOffersTime {
    display: block;
    font-size: 0.8rem;
    text-align: center;
  }
}