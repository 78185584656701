.nftSearch {
  padding-bottom: 2rem;
}

.nftSearchInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  max-width: 40rem;
  padding: 0 0.25rem;
}

.nftSearchResultBox {
  position: absolute;
  bottom: -20rem;
  left: 1px;
  background:gray; 
  background-color: var(--pal-background-secondary);
  /* border: 1px solid var(--pal-background-tertiary); */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 20rem;
  width: 90%;
  max-width: 40rem;
  z-index: 10;
  
  overflow-y: auto;
}

.nftSearchResultItem {
  white-space:nowrap; 
  overflow: hidden;
  padding: 0.5rem;
  font-size: 1.1rem;
}

.nftSearchResultItemRow {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 0.5rem;
}

.nftSearchResultItemNftImage {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 4px;
}

.nftSearchResultItemUserImage {
  height: 1rem;
  width: 1rem;
  object-fit: cover;
  border-radius: 2px;
}

.nftSearchResultNftCreator {
  font-size: 1rem;
  display: flex;
  align-items: center;
  /* padding-left: 0.5rem; */
  /* padding-bottom: 0.5rem; */
}

/* .nftSearchResultItem:hover {
  background-color: var(--pal-background-tertiary);
} */