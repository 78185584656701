.issueDescription {
  padding: 1rem;
}

.issueDescriptionTitle {
  padding-bottom: 1rem;
  font-size: 1.1rem;
}

.issueDescriptionContent {
  padding-bottom: 1rem;
}